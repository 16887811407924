<template>
  <div id="v-pills-personal" role="tabpanel" aria-labelledby="v-pills-personal-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Institutes</h5>
      <hr />
      
     <div class="row" v-if="training_details.length">
        <div class="col-md-12 mb-2" v-for="td in training_details" :key="td.st_institute_id">
          <div class="card">
            <div class="card-body">
              <p>Institute name: {{td.institute_name}}</p>
              <div v-for="course in td.courses" :key="course.course_id">
                <div class="course-details card mb-2">
                  <div class="card-body">
                  <div @click="openDeleteConfirmation(course)" style="position:absolute;right:10px">
                    <button class="btn">
                      <v-icon class="mdi" title="Delete" data-mdb-toggle="tooltip" data-mdb-placement="top" size="14px">{{$icons.delete}}</v-icon>
                    </button>
                  </div>
                    <div class="row">
                      <div class="col-3">Course:</div>
                      <div class="col-3">{{ course.course_name }}</div>
                    </div>
                     <div class="row">
                      <div class="col-3">Start date:</div>
                      <div class="col-3">{{ getDate(course.course_start_date)}}</div>
                    </div>
                     <div class="row">
                      <div class="col-3">End date:</div>
                      <div class="col-3">{{ getDate(course.course_end_date) }}</div>
                    </div>
                     <div class="row">
                      <div class="col-3">Category:</div>
                      <div class="col-3">{{ course.category_name }}</div>
                    </div>
                    <div class="row">
                      <div class="col-3">Location:</div>
                      <div class="col-3">{{ course.training_location }}</div>
                    </div>
                    </div>
                </div>
              </div>
                <div class="text-right">
                  <button class="btn btn-primary" @click="addCourse(td)">Add course</button>
                </div>
            </div>
          </div>
        </div>  
      </div>
      <div class="text-right mb-2">
        <button class="btn btn-primary" @click="addNewInstitute()">Add Institute</button>
      </div>
      <b-modal id="add-institute" size="lg" hide-footer :title="is_add_course ? 'Add course to institute' : 'Add new institute'">
         <form ref="add-institute-form" id="add-institute" class="p-4">
          <div class="row align-items-center mb-2">
            <div id="institute_name" class="col">
              <div class="error-feedback mb-2" v-if="show_noinsti_err">
                Institute not listed. Select 'Other'.
              </div>
              <v-autocomplete no-filter ref="insti_autocomplete" v-model="institute.institute_name" :disabled="is_add_course" label="Select Institute" :items="institutes" @keyup="setInstikeyword" @input="handleInstiInput" item-text="text" item-value="value" :searchable="false" clearable outlined deletable-chips> 
                <template slot="item" slot-scope="data">
                  <template>
                    <v-list-item-content>{{data.item.text}}</v-list-item-content>
                  </template>
                </template>
               </v-autocomplete> 
               <div class="error-feedback" v-if="(typeof validate.response.institute_name != 'undefined' && !validate.response.institute_name.val)">
                {{ validate.response.institute_name.msg }}
              </div>
            </div>
          </div>
          <div id="other_institute_name" class="mb-3" label-for="name" v-if="show_other_institute">
            <v-text-field  type="text" v-if="show_other_institute" :disabled="is_add_course" v-model="institute.other_institute_name" label="Enter institute name"  outlined  @input="validate.checkIfEmpty(institute.other_institute_name, 'other_institute_name', 'Please enter institute name');"></v-text-field>
            <div class="error-feedback" v-if="(typeof validate.response.other_institute_name != 'undefined' && !validate.response.other_institute_name.val)">
              {{ validate.response.other_institute_name.msg }}
            </div>
          </div>
          <div id="institute_course" label-for="Choose course" class="mb-3">
            <v-autocomplete no-filter ref="autocomplete" v-model="institute.course_name" label="Select course" :items="institute_courses"  @input="handleCourseInput" item-text="text" item-value="value" :searchable="false" clearable outlined deletable-chips> 
              <template slot="item" slot-scope="data">
                <template>
                  <v-list-item-content>{{data.item.text}}</v-list-item-content>
                </template>
              </template>
            </v-autocomplete>   
            <div class="error-feedback" v-if="(typeof validate.response.course_name != 'undefined' && !validate.response.course_name.val)">{{ validate.response.course_name.msg }}</div>
          </div>
          <div id="other_course_name" label-for="other_course_name" class="mb-3">
            <v-text-field  type="text" v-if="show_other_course" v-model="institute.other_course_name" label="Enter course name"  outlined  @input="validate.checkIfEmpty(institute.other_course_name, 'other_course_name', 'Please enter course name');"></v-text-field>
            <div class="error-feedback" v-if="(typeof validate.response.other_course_name != 'undefined' && !validate.response.other_course_name.val && institute.course_name == 'other')">
              {{ validate.response.other_course_name.msg }}
            </div>
          </div>
          <div id="categories" v-if="show_other_course" label-for="Choose Category" class="mb-3">
            <v-autocomplete ref="autocomplete" v-model="institute.course_category_id" label="Choose course category" :items="categories" item-text="text" item-value="value" :searchable="false" clearable outlined deletable-chips> 
              <template slot="item" slot-scope="data">
                <template>
                  <v-list-item-content>{{data.item.text}}</v-list-item-content>
                </template>
              </template>
            </v-autocomplete>   
            <div class="error-feedback" v-if="(typeof validate.response.course_category != 'undefined' && !validate.response.course_category.val && institute.course_name == 'other')">{{ validate.response.course_category.msg }}</div>
          </div>
          <div id="training_location" label-for="Choose location" class="mb-3">
            <v-autocomplete ref="autocomplete" v-model="institute.training_location" label="Choose location" :items="locations" item-text="text" item-value="id" :searchable="false" clearable outlined deletable-chips> 
              <template slot="item" slot-scope="data">
                <template>
                  <v-list-item-content>{{data.item.text}}</v-list-item-content>
                </template>
              </template>
            </v-autocomplete>   
            <div class="error-feedback" v-if="(typeof validate.response.training_location != 'undefined' && !validate.response.training_location.val)">
            {{ validate.response.training_location.msg }}
          </div>
            <!-- <div class="error-feedback" v-if="(typeof validate.response.institute_name != 'undefined' && !validate.response.institute_name.val)">{{ validate.response.institute_name.msg }}</div> -->
          </div>
          <div  class="row justify-content-between mb-3">
            <div id="course_start_date" class="col-lg-6" label-for="course_start_date">
              <v-text-field  type="date" v-model="institute.course_start_date" label="Enter start date"  outlined></v-text-field>
              <div class="error-feedback" v-if="(add_err !== 0 && !institute.course_start_date)">
                {{ validate.response.course_start_date.msg }}
              </div>
            </div>
            <div id="course_end_date" class="col-lg-6" label-for="course_end_date">
              <v-text-field  type="date" v-model="institute.course_end_date" label="Enter end date"  outlined></v-text-field>
              <div class="error-feedback" v-if="(typeof validate.response.course_end_date != 'undefined' && !validate.response.course_end_date.val)">
                {{ validate.response.course_end_date.msg }}
              </div>
            </div>
          </div>
           <div class="text-right">
              <b-button variant="primary" @click="AddInstitute()">Save</b-button>
              <!-- <b-button style="cursor: no-drop;color:#fff !important;font-size:10px;" variant="primary" v-if="isAdding" disabled>Saving...</b-button> -->
            </div>
         </form>
      </b-modal>
      <b-modal id="update-success" ref="update-success" size="sm" title=" " body-bg-variant="primary" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Training details updated</div>
    </b-modal>
    </div>
  </div>
</template>

<script>
 
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import Actions from '../libraries/apiActions.js';
import validation from '../libraries/validFormat' ;
import { STATES } from '../store/modules/states/constants';

import '../assets/hire.css';
export default {
  name: "Institutes",
  data() {
    return {
      action: new Actions(),
      categories:[],
      validate: new validation(),
      token: null,
      training_details: [],
      institute_courses: [],
      institute: {
        default: true,
        st_institute_id: null,
        institute_name: null,
        institute_id: null,
        course_name: '',
        course_start_date: null,
        course_end_date: null,
        training_location: null,
        other_institute_name: null,
        course_category_id: null,
        course_id: null,
      },
      show_other_institute: false,
      show_other_course: false,
      institutes: [],
      add_err: 0,
      is_add_course: false,
      show_noinsti_err: false,
    };
  },
   created() {
    const apiFn = this.action;
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        locations: state => state.states.locations.locations,
    })
  },
  beforeCreate() {
      this.$store = store;
  },
  beforeMount(){
    this.token = this.$cookies.get("hire-seeker").token;
    this.getUserTrainingDetails();
    this.getCategories();
  },
  methods: {
    getDate(ts){
        var timestamp = ts * 1000;
        var date = new Date(timestamp);
        return date.getDate()+
            "-"+(date.getMonth()+1)+
            "-"+date.getFullYear()
    },
    openDeleteConfirmation: function(course){
        this.$bvModal.msgBoxConfirm('Are you sure you want to remove'+ course.course_name+' from the list?')
        .then((value) => {
            if(value) {
                this.removeCourse(course.id);
            }
        });
    },
    removeCourse: function(id){
      this.action.deleteInstituteCourse(id,this.token).then(res => {
        if(res.status){
          this.getUserTrainingDetails();
        }
      })
    },
    setInstikeyword() {
      var input_str = this.$refs.insti_autocomplete.$el.querySelector('input').value;
      if (input_str.length >= 3) {
        this.getInstitutes(input_str)
      }
    },
    handleInstiInput: function(value){
      console.log(value)
      if(value == 'other'){
        this.show_other_institute = true;
        this.institute_courses = [{
            text: 'Other',
            value: 'other'
        }];
      } else {
        this.show_other_institute = false;
        var selected_insti = this.institutes.find(option => option.value === this.institute.institute_name);
        if(selected_insti)
        this.institute.st_institute_id = selected_insti.id;
        if(this.training_details.length > 0){
          const training_details_arr = Array.from(this.training_details)
          this.institute.institute_id = training_details_arr.find(
            (institute) => institute.st_institute_id === this.institute.st_institute_id
          )?.institute_id || null;
        }
        this.getInstituteCourse(this.institute.st_institute_id);
      }
    },
    handleCourseInput: function(value){
      if(value == 'other'){
        this.show_other_course = true;
      } else this.show_other_course = false;
    },
    getInstitutes: async function (keyword = null) {
      var req = {}
      this.show_noinsti_err = false;
      if(keyword) req.keyword = keyword;
        this.institutes = [];
        await this.action.getTrainingInstitutes(req).then((res) => {
        if(res.institutes.length > 0){
          res.institutes.forEach((e) => {
            this.institutes.push({
              value: e.institute_name,
              text: e.institute_name,
              id: e.institute_id
            });
          });
        } else {
          this.institutes = [{
              text: 'Other',
              value: 'other'
          }];
          this.show_noinsti_err = true;
        }
      });
    },
    
    getInstituteCourse: function(id){
        this.institute_courses = [];
        this.action.getInstituteCourses(id).then((res) => {
          this.institute_courses = [{
              text: 'Other',
              value: 'other'
          }];
        if(res.courses.length > 0){
          res.courses.forEach((e) => {
            this.institute_courses.push({
              value: e.course_name,
              text: e.course_name,
              id: e.cid
            });
          });
        } 
      });
    },
    getUserTrainingDetails: async function(){
      this.training_details = [];
      await this.action.getUserTrainingDetails(this.token).then(res => {
        if(res.status){
          this.training_details = res.institutes;
          console.log(this.training_details)
        }
      })
    },
    addNewInstitute: function(){
      this.resetForm();
      this.$bvModal.show('add-institute');
    },
    resetForm: function(){
        this.institute.st_institute_id =  null;
        this.institute.institute_name =  null;
        this.institute.institute_id =  null;
        this.institute.course_name =  '';
        this.institute.course_start_date =  null;
        this.institute.course_end_date =  null;
        this.institute.training_location =  null;
        this.institute.other_institute_name =  null;
        this.institute.course_category_id =  null;
        this.institute.course_id =  null;
        this.institute.other_course_name = null;
        this.show_other_institute = false;
        this.show_other_course = false;
        this.show_noinsti_err = false;
        this.institutes = [];
    },
    addCourse: async function(institute){
      this.is_add_course = true;
      this.resetForm();
      await this.getInstitutes(institute.institute_name);
      console.log(this.institutes)
      if(this.institutes.length == 1 && this.institutes[0].value == 'other'){
        console.log('Hellooo')
        this.institute.institute_name = 'other';
        this.show_other_institute = true;
        this.institute.other_institute_name = institute.institute_name
        this.getInstituteCourse();
      } else {
        this.institute.st_institute_id =  institute.st_institute_id;
        this.institute.institute_name =  institute.institute_name;
        this.institute.institute_id =  institute.institute_id;
        this.getInstituteCourse(institute.st_institute_id);
      }
      this.$bvModal.show('add-institute');
    },
    AddInstitute: function(){
      this.add_err = 0;
      this.is_add_course = false;
      this.isAdding = true;
      if(this.validate.checkIfEmpty(this.institute.institute_name, "institute_name", 'Choose training location')?.val === false){
        this.add_err++;
      }
      if(this.validate.checkIfEmpty(this.institute.course_name, "course_name", 'Choose training course')?.val === false){
        this.add_err++;
      }
      if(this.validate.checkIfEmpty(this.institute.training_location, "training_location", 'Choose training location')?.val === false){
        this.add_err++;
      }
      if(this.institute.institute_name == 'other' && this.validate.checkIfEmpty(this.institute.other_institute_name, "other_institute_name", 'Enter institute name')?.val === false){
        this.add_err++;
      }
      if(this.institute.course_name == 'other' && this.validate.checkIfEmpty(this.institute.other_course_name, "other_course_name", 'Enter course name')?.val === false){
        this.add_err++;
      }
      if(this.institute.course_name == 'other' && this.validate.checkIfEmpty(this.institute.course_category_id, "course_category", 'Choose course  category')?.val === false){
        this.add_err++;
      }
      if(this.validate.checkIfEmpty(this.institute.course_start_date, "course_start_date", 'Choose course start date')?.val === false){
        this.add_err++;
      }
       if(this.validate.checkIfEmpty(this.institute.course_end_date, "course_end_date", 'Choose course end date')?.val === false){
        this.add_err++;
      }
      if(this.institute.institute_name == 'other'){
        this.institute.institute_name = this.institute.other_institute_name
        this.institute.st_institute_id = null
      }
      if(this.institute.course_name == 'other'){
        this.institute.course_name = this.institute.other_course_name
      }
      console.log(this.add_err)
      if(this.add_err == 0){
        this.action.addUserTrainingDetails({
              institute: this.institute
            }, this.token).then(res => {
              if(res.status){
                this.getUserTrainingDetails();
                this.$bvModal.hide('add-institute');
                this.$refs['update-success'].show();
                  setTimeout(() =>{
                    this.$refs['update-success'].hide();
                  },3000);
                }
            });
      }
      console.log(this.institute);
    },
    getCategories: async function() {
      await this.action.getCourseCategories().then(async (data) => {
      data.categories.forEach(category_type => {
        this.categories.push({ header: category_type.course_category_name });
        this.categories.push({divider:true})

        category_type.categories.forEach(cat => {
          this.categories.push({
            value: cat.category_id,
            text: cat.category_name
          });
        });
      });
  });
}
  },
};
</script>

<style>
.institute_name {
    color:#0054ad;
    font-weight: bold;
  }
.v-text-field__details {
  display: none !important;
}
.error-feedback{
  margin-bottom: 1rem !important;
  position: unset !important;
}
input{padding: 28px 0px 28px 15px !important;}
</style>